import Vue from "vue";
import Vuex from "vuex";
import cars from "./modules/cars";

Vue.use(Vuex);

export default new Vuex.Store({
    state: {
        isLoading: false,
        loadingCount: 0,
        hasError: false
    },
    mutations: {
        setIsLoading(state, value) {
            state.isLoading = value;
        },
        incrementLoadingCount(state) {
            state.loadingCount++;
        },
        decrementLoadingCount(state) {
            state.loadingCount--;
        },
        setHasError(state, value){
            state.hasError = value;
        }
    },
    actions: {},
    modules: {
        cars
    }
});
