import { getCars, getCar } from "../../api/cars";

const getDefaultState = () => {
    return {
        all: {}
    };
};

const state = getDefaultState();

const getters = {};

const actions = {
    getCars(
        { commit, state },
        payload = { searchField: "", value: "", cancelTokenSource: undefined }
    ) {
        return new Promise(resolve => {
            if (Object.keys(state.all).length === 0) {
                commit("setIsLoading", true, { root: true });
            }
            getCars(payload)
                .then(response => {
                    if (
                        response &&
                        response.data.Status === "Success" &&
                        response.data.Result
                    ) {
                        commit("setCars", response.data.Result);
                    }
                    resolve(response);
                });
        });
    },
    getCar({ commit, state }, id) {
        return new Promise(resolve => {
            if (!(id in state.all)) {
                commit("setIsLoading", true, { root: true });
            }
            getCar(id)
                .then(response => {
                    if (
                        response.data.Status === "Success" &&
                        response.data.Result
                    ) {
                        commit(
                            "setCar",
                            Object.values(response.data.Result)[0]
                        );
                    }
                })
                .finally(() => {
                    resolve();
                });
        });
    }
};

const mutations = {
    resetState(state) {
        Object.assign(state, getDefaultState());
    },
    setCars(state, cars) {
        state.all = cars;
    },
    setCar(state, car) {
        state.all = { ...state.all, [car["Fahrzeug-ID"]]: car };
    }
};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
};
