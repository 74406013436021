function parseCurrencyToFloat(string) {
    if (string !== undefined) {
        string = string.replace(".", "").replace(",", ".");
    }
    return parseFloat(string);
}

function parseValueToCurrency(value, sign = "€") {
    let result = "";
    if (typeof value === "string" && value.charAt(value.length - 3) === ",") {
        value = parseCurrencyToFloat(value).toFixed(2);
    } else {
        value = parseFloat(value).toFixed(2);
    }
    var parts = value.toString().split(".");
    parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ".");
    var joinedParts = parts.join(",");
    if (joinedParts !== "NaN" && sign !== "") {
        result = sign + " " + joinedParts;
    } else if (joinedParts !== "NaN") {
        result = joinedParts;
    }
    return result;
}

function convertKwToPs(value) {
    return Math.round(parseFloat(value) * 1.35962);
}

function numberWithCommas(x) {
    let result = x;
    if(x !== undefined){
        result =  x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
    }
    return result;
}

export { parseCurrencyToFloat, parseValueToCurrency, convertKwToPs, numberWithCommas };
