import { axios } from "@/plugins/axios";
import * as axiosDefault from "axios";

function getCars(
    payload = { searchField: "", value: "", cancelTokenSource: undefined }
) {
    let apiSuffix = "";
    let cancelToken = undefined;
    if (payload.cancelTokenSource && payload.cancelTokenSource.token) {
        cancelToken = payload.cancelTokenSource.token;
    }
    if (payload.searchField !== "" && payload.value !== "") {
        apiSuffix =
            "&searchField=" + payload.searchField + "&value=" + payload.value;
    }
    return axios
        .get("?Function=getCars" + apiSuffix, {
            cancelToken: cancelToken
        })
        .catch(function(thrown) {
            if (axiosDefault.isCancel(thrown)) {
                console.log("Request canceled", thrown.message);
            } else {
                // handle error
            }
        });
}

function getCar(id) {
    return axios.get("?Function=getCars&searchField=Fahrzeug-ID&value=" + id);
}

function getFilters() {
    return axios.get("?Function=getFilters");
}

function saveLead(payload) {
    return axios.post("?Function=saveLead", payload);
}

function getNewCars() {
    return axios({
        method: "post",
        url: "https://www.uniqehorn.eu/fsales/dev/api/?Mode=Car&Function=cars"
    });
}

export { getCars, getCar, getFilters, saveLead, getNewCars };
