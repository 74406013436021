export const setup = {
    data() {
        return {
            loadingInstance: null,
        };
    },
    watch: {
        hasError(value) {
            if (value) {
                this.$alert(
                    "Es ist ein Fehler aufgetreten. Bitte versuche es später noch einmal.",
                    "Bitte beachten",
                    {
                        confirmButtonText: "OK",
                        callback: () => {
                            this.$store.commit("setHasError", false);
                        },
                    }
                );
            }
        },
        isLoading(value) {
            if (value) {
                this.loadingInstance = this.$loading({
                    lock: true,
                    // text: "Loading",
                    // spinner: "el-icon-loading",
                    // background: "rgba(0, 0, 0, 0.7)",
                });
            } else {
                this.loadingInstance.close();
            }
        },
    },
    computed: {
        hasError() {
            return this.$store.state.hasError;
        },
        isLoading() {
            return this.$store.state.isLoading;
        },
    },
};
