// import Vue from "vue";
import {
  parseValueToCurrency,
  convertKwToPs,
  numberWithCommas,
} from "../utils/number";
import "../plugins/element.js";

export default function(Vue) {
  const hostname = window.location.hostname;

  if (process.env.NODE_ENV === "development") {
    require("../styles/scss/main.scss");

    // Skala Debugging
    // require("../styles/scss-skala/theme/index.css");
    // require("../styles/scss-skala/main.scss");

    // Luxner Debugging
    require("../styles/scss-kammerhofer/theme/index.css");
    require("../styles/scss-kammerhofer/main.scss");
  } else {
    require("../styles/scss/main.scss");
    if (hostname === "www.skala.cc") {
      require("../styles/scss-skala/theme/index.css");
      require("../styles/scss-skala/main.scss");
    } else if (hostname === "www.autohaus-luxner.at") {
      require("../styles/scss-luxner/theme/index.css");
      require("../styles/scss-luxner/main.scss");
    } else if(hostname === "www.kammerhofer.cc") {
      require("../styles/scss-kammerhofer/theme/index.css");
      require("../styles/scss-kammerhofer/main.scss");
    }
  }

  Vue.config.productionTip = false;

  Vue.filter("currency", function(value) {
    return parseValueToCurrency(value);
  });

  Vue.filter("ps", function(value) {
    return convertKwToPs(value);
  });

  Vue.filter("dot", function(value) {
    return numberWithCommas(value);
  });

  Vue.prototype.$log = console.log;
  Vue.prototype.$env = process.env.NODE_ENV;
}
