// import "../styles/scss/theme/index.css";
import Vue from "vue";
import {
    Button,
    Row,
    Col,
    Form,
    Input,
    FormItem,
    RadioGroup,
    Radio,
    MessageBox,
    Loading,
    Card,
    Carousel,
    CarouselItem,
    Image,
    Select,
    Option,
    Popover,
    Slider,
    InputNumber,
    DatePicker,
    TimeSelect,
    Checkbox,
    OptionGroup,
} from "element-ui";
import lang from "element-ui/lib/locale/lang/de";

import locale from "element-ui/lib/locale";

locale.use(lang);

Vue.use(Button);
Vue.use(Card);
Vue.use(Row);
Vue.use(Col);
Vue.use(Form);
Vue.use(Input);
Vue.use(FormItem);
Vue.use(RadioGroup);
Vue.use(Radio);
Vue.use(Loading.directive);
Vue.use(Carousel);
Vue.use(CarouselItem);
Vue.use(Image);
Vue.use(Select);
Vue.use(Option);
Vue.use(Popover);
Vue.use(Slider);
Vue.use(InputNumber);
Vue.use(DatePicker);
Vue.use(TimeSelect);
Vue.use(Checkbox);
Vue.use(OptionGroup);

Vue.prototype.$alert = MessageBox.alert;
Vue.prototype.$loading = Loading.service;
