<template>
    <el-card
        class="c-car-thumb"
        :body-style="{ padding: '0px' }"
        shadow="hover"
        @click.native="changePage(car['Fahrzeug-ID'])"
    >
        <!-- <div class="c-car-thumb"> -->
        <div class="c-car-thumb__img" :style="'background-image: url(' + imageUrl + carImages[0] + ')'"></div>       
        <!-- <img class="c-car-thumb__img" :src="carImages[0]" alt="" /> -->
        <div class="c-car-thumb__content">
            <div class="c-car-thumb__title">
                <template v-if="car['Name Marke'] != 'Fremdmarke'">{{ car["Name Marke"] }}</template> {{ car["Name Modell Type"] }}
            </div>
            <el-row>
                <el-col :sm="12"> {{ car["KW"] | ps }} PS </el-col>
                <el-col :sm="12">
                    {{ formatDate(car["Erstzulassung"]) }}
                </el-col>
                <el-col :sm="12"> {{ car["KM"] | dot }} km<br /> </el-col>
                <el-col :sm="12"> {{ car["Fahrzeugtyp-ID"] }}<br /> </el-col>
            </el-row>
            <div class="c-car-thumb__price">
                {{ this.$options.filters.currency(car["Preis"]) }}
            </div>
            <!-- <el-button
                type="primary"
                @click="
                    $router.push({
                        name: 'fahrzeug',
                        params: { id: car['Fahrzeug-ID'] }
                    })
                "
                >Mehr Infos</el-button
            > -->
        </div>
        <!-- </div> -->
    </el-card>
</template>

<script>
// import { checkImageExists } from "@/utils/imagecheck";
import { DateFormatter } from "@/utils/date";

export default {
    name: "CarThumb",
    props: {
        carId: {
            required: true,
            type: String
        }
    },
    data() {
        return {
            imageUrl: process.env.VUE_APP_IMAGEURL
            // carImages: []
        };
    },
    watch: {
        // car() {
        //     this.fillCarImages();
        // }
    },
    mounted() {
        // this.fillCarImages();
    },
    computed: {
        car() {
            return this.$store.state.cars.all[this.carId];
        },
        carImages(){
            let result = [];
            if(Array.isArray(this.car.Bild)){
                result = this.car.Bild;
            }
            return result;
        }
    },
    methods: {
        // fillCarImages() {
        //     this.carImages = [];
        //     let imageUrl =
        //         process.env.VUE_APP_IMAGEURL +
        //         this.car["Fahrzeug-ID"] +
        //         "_1.jpg";
        //     checkImageExists(imageUrl, imgUrl => {
        //         if (imgUrl) {
        //             this.carImages.push(imgUrl);
        //         } else {
        //             this.carImages.push("./placeholder_car.jpg");
        //         }
        //         this.carImages.sort();
        //     });
        // },
        formatDate(dateString) {
            if(dateString == null){
                return "k.A.";
            }
            return DateFormatter.formatDate(new Date(dateString), "MM.YYYY");
        },
        changePage(carId) {
            if (this.$router) {
                this.$router.push({
                    name: "fahrzeug",
                    params: { id: carId }
                });
            } else {
                window.location.href =
                    process.env.VUE_APP_ROOT + "fahrzeug/" + carId;
            }
        }
    }
};
</script>

<style scoped lang="scss">
.c-car-thumb {
    // border: solid 2px green;
    // padding: 0;
    margin-bottom: 20px;
    cursor: pointer;

    &:hover {
        transform: scale(1.04);
    }
    &__title {
        font-size: medium;
        font-weight: bold;
        margin-bottom: 10px;
    }
    &__price {
        margin-top: 10px;
        font-size: large;
        font-weight: bold;
    }
    &__content {
        padding: 10px;
    }
    &__img {
        width: 100%;
        height: 281px;
        background-size: cover;
        background-position: center center;
    }
    .el-button {
        width: 100%;
    }
}

</style>
